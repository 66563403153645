import React, { useState } from "react";
import "./NavBar.css";
import { Link, NavLink} from "react-router-dom";
 const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav>
      <Link to="https://wic.org.in/" className="title">
        <img  src="./logo.webp" alt="ll" />
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink onClick={() => setMenuOpen(!menuOpen)} className="link-font" to="/">HOME</NavLink>
        </li>
        <li>
          <NavLink onClick={() => setMenuOpen(!menuOpen)} className="link-font" to="/About-womeki-investors-club">ABOUT</NavLink>
        </li>
        <li>
          <NavLink onClick={() => setMenuOpen(!menuOpen)} className="link-font" to="/Projects">PROJECTS</NavLink>
        </li>
        <li>
          <NavLink onClick={() => setMenuOpen(!menuOpen)} className="link-font" to="/Gallery">GALLERY</NavLink>
        </li>
        <li>
          <NavLink onClick={() => setMenuOpen(!menuOpen)} className="link-font" to="/Contact">CONTACT</NavLink>
        </li>
        <li>
          <button onClick={() => setMenuOpen(!menuOpen)} className="link-font1" ><a href="http://womekiinvestorsclub.co.in/Chanel-Partner-form">REGISTER</a></button>
        </li>
        <li>
        </li>
      </ul>
    </nav>
  );
};
export default NavBar